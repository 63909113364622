import { httpClient } from '@/httpClient';

export default {
  state: {
    pending: true,
    additional: {},
    options: {},
    createdAt: null,
    updatedAt: null,
    originalPlanId: null,
  },
  mutations: {
    setPlanMeta(state, {
      additional,
      options,
      createdAt,
      updatedAt,
      originalPlanId,
    }) {
      state.additional = additional;
      state.options = options;
      state.createdAt = createdAt;
      state.updatedAt = updatedAt;
      state.originalPlanId = originalPlanId;
      state.pending = false;
    },

  },
  actions: {
    getPlanMeta(store, id) {
      return httpClient.get(`/api/plans/${id}/meta`)
        .then(({ data }) => {
          store.commit('setPlanMeta', data);
          return data;
        });
    },
  },
};
