import { httpClient } from '@/httpClient';
import plans from '@/store/crm/plans';
import myPlans from '@/store/crm/my-plans';
import apartmentPrograms from '@/store/crm/apartment-programs';
import apartments from '@/store/crm/apartments';
import statistic from '@/store/crm/statistic';
import customers from '@/store/crm/customers';
import { Role } from '@/enums/roles';

export default {
  namespaced: true,
  state: {
    currentPlan: null,
    isCurrentPlanLoading: false,
    statusHistory: {
      items: [],
      isLoading: false,
    },
    tagsList: [],
    simpleUsersList: [],
    isSimpleUsersListLoading: false,

    modals: {
      changeNinja: {
        isVisible: false,
        isLoading: false,
      },
    },
  },
  mutations: {
    setCurrentPlan(state, data) {
      state.currentPlan = data;
    },
    setStrategicData(state, {
      data,
    }) {
      state.currentPlan.region = data.region;
      state.currentPlan.developer = data.developer;
      state.currentPlan.complex = data.complex;
      state.currentPlan.address = data.address;
    },
    setStrategicDataRegion(state, data) {
      state.currentPlan.region = data;
    },
    setStrategicDataDeveloper(state, data) {
      state.currentPlan.developer = data;
    },
    setStrategicDataComplex(state, data) {
      state.currentPlan.complex = data;
    },
    setStrategicDataAddress(state, data) {
      state.currentPlan.address = data;
    },
    setTagsList(state, data) {
      state.tagsList = data;
    },
    setStatusHistory(state, { items }) {
      state.statusHistory.items = items;
    },
    setStatusHistoryLoading(state, isLoading) {
      state.statusHistory.isLoading = isLoading;
    },
    getSimpleUsersList(state, data) {
      state.simpleUsersList = data;
    },
    setIsSimpleUsersListLoading(state, value) {
      state.isSimpleUsersListLoading = value;
    },
    setCurrentPlanLoading(state, isLoading) {
      state.isCurrentPlanLoading = isLoading;
    },
    setCurrentPlanName(state, name) {
      state.currentPlan.name = name;
    },
    setChangeNinjaModalVisibility(state, visibility) {
      state.modals.changeNinja.isVisible = visibility;
    },
    setChangeNinjaModalLoading(state, isLoading) {
      state.modals.changeNinja.isLoading = isLoading;
    },
    setCurrentPlanNinja(state, { id, name }) {
      state.currentPlan.ninja = { id, name };
    },
  },
  getters: {
    simpleNinjasList: (state) => state.simpleUsersList
      .filter(({ roles }) => roles.map(({ id }) => id).includes(Role.Ninja)),
  },
  actions: {
    getCrmPlanById(store, id) {
      return httpClient.get(`/api/crm/plans/${id}/`).then((resp) => {
        const { data } = resp;
        store.commit('setCurrentPlan', data);
        return data;
      });
    },
    getCrmPlan3dById({ commit }, id) {
      commit('setCurrentPlanLoading', true);

      return httpClient.get(`/api/crm/plans/${id}/plan_3d`)
        .then((resp) => {
          commit('setCurrentPlan', resp.data);
        })
        .finally(() => commit('setCurrentPlanLoading', false));
    },
    changeStrategicData(store, {
      id, region, developer, complex, address,
    }) {
      return httpClient.post(`api/crm/plans/${id}/change_strategic_data`, {
        region, developer, complex, address,
      }).then(({ data }) => {
        store.commit('setStrategicData', {
          data,
        });
        return data;
      });
    },
    getCrmPlan3dByIdWithoutLoading({ commit }, id) {
      return httpClient.get(`/api/crm/plans/${id}/plan_3d`).then((resp) => commit('setCurrentPlan', resp.data));
    },
    changePlanName({ commit, state }, { id, name }) {
      const oldName = state.currentPlan.name;

      commit('setCurrentPlanName', name);

      return httpClient.post(`/api/crm/plans/${id}/rename`, { id, name })
        .then((resp) => resp.data)
        .catch(() => commit('setCurrentPlanName', oldName));
    },
    changeCrmPlanNinja({ commit, state }, { id, ninjaId }) {
      commit('setChangeNinjaModalLoading', true);

      return httpClient.post(`/api/crm/plans/${id}/change_ninja`, { ninjaId })
        .then((resp) => resp.data)
        .finally(() => {
          commit('setCurrentPlanNinja', state.simpleUsersList.find((item) => item.id === ninjaId));
          commit('setChangeNinjaModalLoading', false);
          commit('setChangeNinjaModalVisibility', false);
        });
    },
    changeCrmPlanNinja3d({ commit, state }, { id, ninjaId }) {
      commit('setChangeNinjaModalLoading', true);

      return httpClient.post(`/api/crm/plans/${id}/change_ninja_3d`, { ninjaId })
        .then((resp) => resp.data)
        .finally(() => {
          commit('setCurrentPlanNinja', state.simpleUsersList.find((item) => item.id === ninjaId));
          commit('setChangeNinjaModalLoading', false);
          commit('setChangeNinjaModalVisibility', false);
        });
    },
    getTagsList(store, params) {
      return httpClient.get('/api/crm/tags/', { params }).then((resp) => {
        const { data } = resp;
        store.commit('setTagsList', data);
        return data;
      });
    },
    attachTagsToPlan(store, request) {
      return httpClient.post(`/api/crm/plans/${request.id}/tags`, request)
        .then((resp) => resp.data);
    },
    getStatusHistory({ commit }, { id, type }) {
      commit('setStatusHistoryLoading', true);

      return httpClient.get(`api/crm/plans/${id}/status_logs`, { params: { type } })
        .then(({ data }) => {
          commit('setStatusHistory', data);
          return data;
        })
        .finally(() => commit('setStatusHistoryLoading', false));
    },
    getSimpleUsersList({ state, commit }) {
      if (state.isSimpleUsersListLoading || state.simpleUsersList.length !== 0) {
        return state.simpleUsersList;
      }

      commit('setIsSimpleUsersListLoading', true);

      return httpClient.get('/api/users/').then(({ data }) => {
        commit('getSimpleUsersList', data);
        return data;
      }).finally(() => {
        commit('setIsSimpleUsersListLoading', false);
      });
    },
  },
  modules: {
    myPlans,
    apartmentPrograms,
    apartments,
    statistic,
    plans,
    customers,
  },
};
