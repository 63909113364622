import { httpClient } from '@/httpClient';
import meta from '@/store/plans/meta';
import list from '@/store/plans/list';
import { api } from '@/api';
import { map, flatten } from 'lodash';
import { StepStatus } from '@/enums/StepStatus';

export default {
  namespaced: true,
  state: {
    current: {
      id: null,
      status: {},
      paths: {},
      hiddenPaths: {},
      hasModel: false,
      hasPanorama: false,
      links: {},
      isPublic: false,
      style: {},
      unrealEnvironment: {},
      crmPlanId: null,
    },
    uploadModal: {
      isVisible: false,
    },
  },
  mutations: {
    setPlan(state, {
      id,
      crm_plan_id: crmPlanId,
      status,
      paths,
      hiddenPaths,
      hasModel,
      hasPanorama,
      links,
      style,
      unreal_environment: unrealEnvironment,
      public: isPublic,
    }) {
      state.current.id = id;
      state.current.crmPlanId = crmPlanId;
      state.current.status = status;
      state.current.paths = paths;
      state.current.hiddenPaths = hiddenPaths;
      state.current.hasModel = hasModel;
      state.current.hasPanorama = hasPanorama;
      state.current.links = links;
      state.current.style = style;
      state.current.unrealEnvironment = unrealEnvironment;

      if (isPublic !== state.current.isPublic) {
        state.current.isPublic = isPublic;
      }
    },
    setPublicPlan(state, {
      id,
      crm_plan_id: crmPlanId,
      paths,
      hasModel,
      hasPanorama,
      links,
    }) {
      state.current.id = id;
      state.current.crmPlanId = crmPlanId;
      state.current.paths = paths;
      state.current.hasModel = hasModel;
      state.current.hasPanorama = hasPanorama;
      state.current.links = links;
      state.current.isPublic = true;

      state.current.status = {
        neural: StepStatus.FINISH,
        furniture: StepStatus.FINISH,
        unreal: StepStatus.FINISH,
      };
    },
    setPlanVisibility(state, value) {
      state.current.isPublic = value;
    },
    setPlanId(state, value) {
      state.current.id = value;
    },
    setUploadModalVisibility(state, visibility) {
      state.uploadModal.isVisible = visibility;
    },
  },
  actions: {
    getPlanById({ commit }, id) {
      return api.getServicePlanById(id).then(({ data }) => {
        commit('setPlan', data);
        return data;
      });
    },
    getPublicPlanById(store, id) {
      return httpClient.get(`/api/plans/${id}/public`).then(({ data }) => {
        store.commit('setPublicPlan', data);
        return data;
      });
    },
    deletePlanById(store, id) {
      return httpClient.delete(`/api/plans/${id}`);
    },
    reloadPlanById(store, id) {
      return httpClient.post(`/api/plans/${id}/reload`);
    },
    togglePlanVisibility({ commit, dispatch, state }) {
      const newVisibility = !state.current.isPublic;

      commit('setPlanVisibility', newVisibility);

      const type = newVisibility ? 'makePlanPublic' : 'makePlanPrivate';

      dispatch(type, state.current.id);
    },
    makePlanPublic(store, id) {
      return httpClient.post(`/api/plans/${id}/make_public`);
    },
    makePlanPrivate(store, id) {
      return httpClient.post(`/api/plans/${id}/make_private`);
    },
    makePlanPathsVisible(store, request) {
      return httpClient.post(`/api/plans/${request.id}/make_paths_visible`, request);
    },
    makePlanPathsHidden(store, request) {
      return httpClient.post(`/api/plans/${request.id}/make_paths_hidden`, request);
    },

    uploadPlan(store, request) {
      const formData = new FormData();
      Object.keys(request).forEach((key) => {
        const value = request[key];
        if (!value) return;

        if (value instanceof Array) {
          const k = `${key}[]`;
          value.forEach((v) => formData.append(k, v));
        } else if (typeof value === 'boolean') {
          formData.append(key, value ? '1' : '0');
        } else {
          formData.append(key, value);
        }
      });

      return httpClient.post('/api/steps/start', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then(({ data }) => data);
    },

    changeAndReloadPlan(store, request) {
      const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
      const formData = new FormData();

      Object.keys(request).forEach((key) => {
        const value = request[key];

        if (value instanceof Array) {
          const k = `${key}[]`;
          value.forEach((v) => formData.append(k, v));
        } else if (typeof value === 'boolean') {
          formData.append(key, value ? '1' : '0');
        } else {
          formData.append(key, value);
        }
      });

      return httpClient.post(`/api/plans/${store.state.current.id}/change_and_reload`, formData, headers)
        .then(({ data }) => data);
    },
    openUploadModal({ commit }) {
      commit('setUploadModalVisibility', true);
    },
    closeUploadModal({ commit }) {
      commit('setUploadModalVisibility', false);
    },
  },
  getters: {
    getPathsAsArray: (state) => {
      const values = map(state.current.paths);
      const valuesWithoutEmpty = values.filter((item) => item && item.length);
      return flatten(valuesWithoutEmpty);
    },
    getHiddenPathsMap: (state, getters) => {
      const all = getters.getPathsAsArray;
      const hidden = Object.values(state.current.hiddenPaths);

      return all.reduce((acc, item) => {
        acc[item] = !hidden.includes(item);
        return acc;
      }, {});
    },
  },
  modules: {
    meta,
    list,
  },
};
